/* eslint-disable */
(function ($) {
    const App = {
      royalMobMenu() {
        $("#mobile-bars").sidr({
          name: "sidr",
          side: "left",
          speed: 400,
          displace: false,
          timing: "ease",
          onOpen() {
            $("#mobile-bars i").removeClass("fa-bars").addClass("fa-times");
            $("body").css({
              position: "fixed",
              width: "100%",
              background: "rgba(0, 0, 0, 0.5)",
            });
            $("#sidr").css("display", "block");
          },
          onClose() {
            $("#mobile-bars i").removeClass("fa-times").addClass("fa-bars");
            $("body").css({
              position: "static",
              width: "auto",
              background: "transparent",
            });
          },
        });
        $("#sidr").css("display", "block");
        $(document).on("click", function (e) {
          if (!$(e.target).closest("#sidr").length) {
            $.sidr("close", "sidr");
          }
        });
  
        /*sidr sub menu*/
        const sidrParentMenu = $("#sidr #mobile-view li.menu-item-has-children");
        const sidrSubmenu = $("#sidr #mobile-view ul.sub-menu");
        const parentAncor = $(
          "#sidr #mobile-view li.menu-item-has-children"
        ).children("a");
  
        $('<i class="fa-solid fa-chevron-down"></i>').appendTo(parentAncor);
        sidrSubmenu.hide();
        sidrParentMenu.children("a").on("click", function (e) {
          e.preventDefault();
          $(this).next("ul.sub-menu").stop().slideToggle();
          $(this).children("i").toggleClass("fa-chevron-down fa-chevron-up");
        });
        $("ul#mobile-view li.menu-item-has-children a").css({
          display: "flex",
          "justify-content": "space-between",
          "align-items": "center",
        });
      },
      royalVenobox() {
        let venoBox = new VenoBox({
          selector: ".image-popup",
          bgcolor: "#3C92CA",
          numeration: true,
          spinner: "wandering-cubes",
        });
      },
      royalSlider(){
        var swiper = new Swiper(".royal-slider", {
          spaceBetween: 30,
          effect: "fade",
          loop: true,
        //   autoplay: {
        //     delay: 2500,
        //     disableOnInteraction: false,
        //   },
        //   pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true,
        //   },
        });
      },
      royalAccordion() {
            var allPanels = $(".royal-accordion-area .single-accordion p").hide();
            var allIcons = $(".royal-accordion-area .single-accordion .accordion-icon")
                .removeClass("fa-angles-down")
                .addClass("fa-angles-right");
        
            // Open the first accordion on page load
            var firstAccordion = $(".royal-accordion-area .single-accordion").first();
            var firstPanel = firstAccordion.find("p");
            var firstIcon = firstAccordion.find(".accordion-icon");
        
            firstPanel.show(); // Show the first panel
            firstIcon.removeClass("fa-angles-right").addClass("fa-angles-down"); // Ensure correct icon
        
            $(".royal-accordion-area .single-accordion h4").click(function () {
                var $this = $(this);
                var $targetPanel = $this.next("p");
                var $icon = $this.closest(".single-accordion").find(".accordion-icon");
        
                if ($targetPanel.is(":visible")) {
                    $targetPanel.slideUp();
                    $icon.removeClass("fa-angles-down").addClass("fa-angles-right");
                } else {
                    allPanels.slideUp();
                    allIcons.removeClass("fa-angles-down").addClass("fa-angles-right");
                    $targetPanel.slideDown();
                    $icon.removeClass("fa-angles-right").addClass("fa-angles-down");
                }
                return false;
            });
        }
     
    };
  
    App.init = function () {
      App.royalMobMenu();
    //   App.portVenobox();
      App.royalSlider();
      App.royalAccordion();
      // App.stickyHeader();
    };
    $(function () {
      App.init();
    });
  })(jQuery);
  